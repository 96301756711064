<template>
  <DefaultTemplate 
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading">
    <div id="credit-detail" v-if="!loading">
      <div class="box-name-page">
        <div>
          <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="set-breadcrumb"
          >
            <el-breadcrumb-item :to="{ path: '/manage/credit' }"
              >จัดการข้อมูลสินเชื่อ</el-breadcrumb-item
            >
            <el-breadcrumb-item> 
              <span v-if="sizeLarge">สินเชื่อสำหรับธุรกิจขนาดใหญ่</span>
              <span v-else>{{ dataCredit.creditName }}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
          <p class="title mg-t-4">
            <span v-if="sizeLarge" class="font-weight-600"
              >สินเชื่อสำหรับธุรกิจขนาดใหญ่</span
            >
            <span v-else>{{ dataCredit.creditName }}</span>
          </p>
        </div>
      </div>
      <el-row :gutter="30">
        <!-- <SizeL
          :dataCredit="dataCredit"
          v-if="sizeLarge"
          @morePicture="openDialogPicture"
        /> -->
        <SizeSM :dataCredit="dataCredit" @morePicture="openDialogPicture" />
      </el-row>
    </div>
    <el-dialog
      class="dialog-custom user-list"
      v-if="dialogPicture"
      :visible.sync="dialogPicture"
      width="70%"
      top="10vh"
      center
    >
      <img :src="imgMore" alt="" class="w-100" />
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import SizeSM from "@/components/credit/detail/SizeSM";
import SizeL from "@/components/credit/detail/SizeL";
import { HTTP } from "@/service/axios";

export default {
  components: {
    DefaultTemplate,
    SizeSM,
    SizeL
  },
  data() {
    return {
      idData: this.$route.params.id,
      sizeLarge: false,
      loading: true,
      dataCredit: [],
      imgMore: "",
      dialogPicture: false
    };
  },
  computed: {
    _token() {
      return this.$store.state._token;
    }
  },
  mounted() {
    this.getCredit();
  },
  methods: {
    openDialogPicture(getImg) {
      this.imgMore = getImg;
      this.dialogPicture = true;
    },
    async getCredit() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.get(`credit/${this.idData}`)
        .then((res) => {
          if (res.data.success) {
            this.dataCredit = res.data.obj;
            // this.checkSize();
            this.loading = false;
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
          return res;
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else if (
            e.response.data.status == 404 ||
            e.response.data.status == 422
          ) {
            this.$message({
              message: "ไม่พบข้อมูลสินเชื่อ สินเชื่อ",
              type: "error",
              duration: 4000
            });
            this.$router.push(`/manage/credit`);
          } else {
            this.alertCatchError(e);
          }
          return {
            data: {
              data: e,
              success: false
            }
          };
        });
      return res;
    }
    // checkSize() {
    //   if (this.dataCredit.creditSizeL) {
    //     this.sizeLarge = true;
    //   } else {
    //     this.sizeLarge = false;
    //   }
    // },
  }
};
</script>
