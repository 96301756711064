<template>
  <div>
    <el-col :span="24">
      <div class="box-table-detail">
        <p class="font-24 text-center mg-b-6">เกณฑ์ในการพิจารณาสินเชื่อ</p>
        <TableDetailList :data="dataTable" page="credit" />
      </div>
    </el-col>
    <el-col :span="24">
      <el-row :gutter="30" class="custom-flex">
        <el-col :span="24" :sm="8" :md="6" class="mg-t-6 text-center">
          <img
            v-if="dataCredit.image != ''"
            :src="imgUrlTERAK + dataCredit.image"
            alt=""
            @click="$emit('morePicture', imgUrlTERAK + dataCredit.image)"
            class="imgCredit cursor-pointer"
          />
          <img
            v-else
            :src="require(`@/assets/image-default.jpg`)"
            alt=""
            class="imgCredit"
          />
        </el-col>
        <el-col :span="24" :sm="16" :md="18" class="mg-t-6">
          <el-tabs type="card" class="tab-card-sm">
            <el-tab-pane label="1" v-if="dataCredit.statusOne">
              <span slot="label">
                <p>{{ dataCredit.titleOne }}</p>
                <!-- <p>บริการ</p> -->
              </span>
              <div class="inside">
                <p class="title">{{ dataCredit.titleOne }}</p>
                <div class="output ql-snow">
                  <div
                    class="ql-editor"
                    v-html="dataCredit.attributeProductService"
                  ></div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="2" v-if="dataCredit.statusTwo">
              <span slot="label">
                <p>{{ dataCredit.titleTwo }}</p>
              </span>
              <div class="inside">
                <p class="title">{{ dataCredit.titleTwo }}</p>
                <div class="output ql-snow">
                  <div
                    class="ql-editor"
                    v-html="dataCredit.taxOrIncrease"
                  ></div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="3" v-if="dataCredit.statusThree">
              <span slot="label">{{ dataCredit.titleThree }}</span>
              <div class="inside">
                <p class="title">{{ dataCredit.titleThree }}</p>
                <div class="output ql-snow">
                  <div class="ql-editor" v-html="dataCredit.limitMaximum"></div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="4"  v-if="dataCredit.statusFour">
              <span slot="label">{{ dataCredit.titleFour}}</span>
              <div class="inside">
                <p class="title">{{ dataCredit.titleFour}}</p>
                <div class="output ql-snow">
                  <div class="ql-editor" v-html="dataCredit.guarantee"></div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="5" v-if="dataCredit.statusFive">
              <span slot="label">{{ dataCredit.titleFive}}</span>
              <div class="inside">
                <p class="title">{{ dataCredit.titleFive}}</p>
                <div class="output ql-snow">
                  <div class="ql-editor" v-html="dataCredit.property"></div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="6" v-if="dataCredit.statusSix">
              <span slot="label">{{ dataCredit.titleSix}}</span>
              <div class="inside">
                <p class="title">{{ dataCredit.titleSix}}</p>
                <div class="output ql-snow">
                  <div class="ql-editor" v-html="dataCredit.condition"></div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="7" v-if="dataCredit.statusSeven">
              <span slot="label">{{ dataCredit.titleSeven}}</span>
              <div class="inside">
                <p class="title">{{ dataCredit.titleSeven}}</p>
                <div class="output ql-snow">
                  <div class="ql-editor" v-html="dataCredit.description"></div>
                </div>
              </div>
            </el-tab-pane>
             <el-tab-pane label="8" v-if="dataCredit.statusEight">
              <span slot="label">{{ dataCredit.titleEight}}</span>
              <div class="inside">
                <p class="title">{{ dataCredit.titleEight}}</p>
                <div class="output ql-snow">
                  <div class="ql-editor" v-html="dataCredit.detailsEight"></div>
                </div>
              </div>
            </el-tab-pane>
             <el-tab-pane label="9" v-if="dataCredit.statusNine">
              <span slot="label">{{ dataCredit.titleNine}}</span>
              <div class="inside">
                <p class="title">{{ dataCredit.titleNine}}</p>
                <div class="output ql-snow">
                  <div class="ql-editor" v-html="dataCredit.detailsNine"></div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </el-col>
  </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.bubble.css"; // for bubble theme
import Quill from "quill";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module--fix-imports-error";
import AutoLinks from "quill-auto-links";

import "quill/dist/quill.snow.css";
import "element-ui/lib/theme-chalk/display.css";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
const quillTable = require("quill-table");
Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register("modules/table", quillTable.TableModule);
Quill.register("modules/autoLinks", AutoLinks);

const maxRows = 4;
const maxCols = 5;
const tableOptions = [];
for (let r = 1; r <= maxRows; r++) {
  for (let c = 1; c <= maxCols; c++) {
    tableOptions.push("newtable_" + r + "_" + c);
  }
}
import TableDetailList from "@/components/table/TableDetailList";

export default {
  components: {
    quillEditor,
    TableDetailList,
  },
  props: ["dataCredit"],
  mounted() {
      this.dataCredit.grades.sort((a, b) => {
      let modifier = 1;
      if (a.gradeNo < b.gradeNo) return -1 * modifier;
      if (a.gradeNo > b.gradeNo) return 1 * modifier;
      return 0;
    });
      this.dataCredit.operatorType.sort((a, b) => {
      let modifier = 1;
      if (a < b) return -1 * modifier;
      if (a > b) return 1 * modifier;
      return 0;
    });
    this.dataTable.push(this.dataCredit);
     if (this.dataCredit.grades.length > 3 || this.dataCredit.cateIndustryType.length > 3 || this.dataCredit.projectTERAK.length > 3 ) {
      this.dataTable.push({
        _id: 99999,
        // grades: [],
        // operatorType:[],
        // cateIndustryType: [],
        // projectTERAK: [],
      });
    }
  },
  data() {
    return {
      dataTable: [],
      imgUrlTERAK: process.env.VUE_APP_BASE_URL_TERAK_IMAGE,
      editorOption: {
        placeholder: "",
        bounds: ".editor",
        modules: {
          autoLinks: true,
          table: true,
          imageResize: {
            displaySize: true,
          },
          imageDrop: true,
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ color: [] }, { background: [] }],
              [{ direction: "rtl" }, "link"],
              [
                { table: tableOptions },
                { table: "append-row" },
                { table: "append-col" },
              ],
              ["clean"],
            ],
          },
        },
      },
    };
  },
};
</script>