<template>
  <div>
    <el-col :span="24">
      <div class="name-head-L">
        <p>
          <span v-if="dataCredit.interestType == 3">สินเชื่อระยะสั้น</span>
          <span v-else-if="dataCredit.interestType == 4"
            >สินเชื่อระยะกลาง-ยาว</span
          >
        </p>
      </div>
      <el-row :gutter="30">
        <el-col :span="24" :sm="12" class="mg-y-5" style="position: relative">
          <el-card class="card-normal size-L" shadow="never">
            <el-row :gutter="20">
              <el-col :span="24" :md="12" :lg="10" class="tab-left">
                <img
                  v-if="dataCredit.image != ''"
                  :src="imgUrlTERAK + dataCredit.image"
                  alt=""
                  @click="
                    $emit('morePicture', imgUrlTERAK + dataCredit.image)
                  "
                  class="img-content cursor-pointer"
                />
                <img
                  v-else
                  :src="require(`@/assets/image-default.jpg`)"
                  alt=""
                  class="img-content"
                />
              </el-col>
              <el-col :span="24" :md="12" :lg="14">
                <div style="position: relative">
                  <p class="name-credit">{{ dataCredit.creditName }}</p>
                </div>
              </el-col>
            </el-row>
          </el-card>
          <!-- <el-card class="card-normal size-L" shadow="never">
            <img
              v-if="dataCredit.file.length > 0"
              :src="imgUrl + dataCredit.file[0].fileName"
              alt=""
              class="img-content"
            />
            <img
              v-else
              :src="require(`@/assets/image-default.jpg`)"
              alt=""
              class="img-content"
            />

            <div style="position: relative">
              <p class="title">{{ dataCredit.creditName }}</p>
            </div>
          </el-card> -->
        </el-col>
      </el-row>
      <!-- <el-row :gutter="30" class="custom-flex"> -->
      <!-- <el-col :span="24" :sm="8" :md="6" class="mg-t-6 text-center">
          <img
            :src="require(`@/assets/credit/${dataCredit.img}`)"
            alt=""
            class="imgCredit"
          />
        </el-col>
        <el-col :span="24" :sm="16" :md="18" class="mg-t-6">
          <div class="tab-card-sm size-L">
            <div class="title">{{ dataCredit.title }}</div>
            <p>{{ dataCredit.title }}</p>
            <el-checkbox-group v-model="checkList" class="checkbox-L">
              <el-checkbox label="1">สินเชื่อโครงการลงทุน</el-checkbox>
              <el-checkbox label="2">บริการประกันการส่งออก</el-checkbox>
              <el-checkbox label="3">บริการประกันความเสี่ยงการลงทุน</el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col> -->
      <!-- </el-row> -->
    </el-col>
  </div>
</template>
<script>
export default {
  props: ["dataCredit"],
  data() {
    return {
      imgUrlTERAK: process.env.VUE_APP_BASE_URL_TERAK_IMAGE,
    };
  },
};
</script>